.App {
    --color-letras: #d2c226;
    --jugadores-size: 6vw;
    --points-size: 8vw;
    --tituloTorneo-size: 3vw;
    --relojTv-size: 2.5vw;
    --nombreClub-size: 3vw;
  }
.contianerPartidoTvLed{
    height: 100vh;
    background-color:black;
    font-family: system-ui;
    padding: 1vh 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.nombre_jugadorLed{
    text-align: -webkit-left;
    line-height: 12vh;
}
.resultadosTvLed{
    background-color: #19331f;
    height: 60vh;
}
.texto_nombresLed{
    font-family: system-ui;
    font-weight: bold;
    font-size: var(--jugadores-size);
    color: var(--color-letras);
}
.nombresTvLed{
    padding: 0 1vw;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}
/*dbe546*/
.nombretorneoLed{
    height: 10vh;
    width: 100%;
    display: flex;
    background-color: #374a3b;
    font-size: var(--tituloTorneo-size);
    flex-direction: row;
    justify-content: center;
    font-family: system-ui;
    font-weight: bold;
}
.nombretorneoLed .nombreTor{
    color: white;
    display: flex;
    width: 100%;
    text-align: center;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}
.pistaTvLed{
    
    padding: 1rem;
    width: 100%;
    color: #114d0b;
    background-color: #356b4240;

}
.jugador1TvLed, .jugador2TvLed{
    
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.pelotaTvLed{
    width: 4vw;
}

.logoPistaLed{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logoPistaLed .logo_pistaLed{
    width: 310px;
}
.nombre_horaLed{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.jugadorTvLed{
    width: 60%;
    min-width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.resultadoTvLed{
    width: 40%;
    min-width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--points-size);
    font-family: arial;
    font-weight: bold;
}
.grupo1Led{    
    width: 100%;
    align-items: center;
    flex-direction: row;
    display: flex;
    color: var(--color-letras);
    justify-content: center;
}
.grupo2Led{
    justify-content: flex-end;
    min-width: 12vw;
    align-items: center;
    flex-direction: row;
    display: flex;
    color: var(--color-letras);
    background-color: #112917;
}

.setTvLed{
    padding: 0px 2vw;
}

.publicidadLed{
    display: flex;
    height: 14vh;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #374a3b;
    padding: 0px 2vw;
}
.logoTvLed{
    height: 10vh;
}
.logoTv2Led{
    width: 22vw;
}
.tiempoTvLed{
    font-size: var(--relojTv-size);
    flex-direction: row;
    font-family: system-ui;
    font-weight: bold;
    color:white;
}
.nombreClub{
    font-size: var(--nombreClub-size);
    font-family: system-ui;
    font-weight: bold;
}
.puntosTvLed{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    width: 100%;
   
}


.partidotv{
    background-image: url('../../Imagenes/pista3.jpg');
}
