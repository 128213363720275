.DetallePartidoTorneoContainer{
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: 1px solid #8080804a;

    &__upperInfo{
        font-size: 0.8em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: lightslategrey;
        &__stats{
            cursor: pointer;
            font-weight: 600;
        }
    }
    .DetallePartidoTorneo{
        
        display: flex;
        flex-direction: row;
        &__horaPista{
            & i {
                padding-right: 10px;
            }
            width: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &__hora{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: red;
                font-weight: 600;
            }
            &__horarioComienzo{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            &__check{
                min-height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: self-end;
                color:green;
                
            }
        }
        &__informacion{
            width: 85%;
            display: flex;
            flex-direction: row;
            padding: 0px 8px;
            &__jugadores{
                width: 65%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .pierde{
                    font-weight: normal;
                }
                &__jugador{
                        align-self: self-start;
                }
                .gana{
                    font-weight: bold;
                }
            }
            &__resultados{
                width: 35%;
                display: flex;
                flex-direction: row;
                &__puntos{
                    width: 46%;
                    display: flex;
                    flex-direction: row;
                    &__punto{
                        width: 100%;
                        &__pelota{
                            .iconoSaque{
                            height: 20px;
                            }
                            .iconoSaque_hidden{
                            visibility: hidden;
                            height: 20px;
                            }
                        }
                        &__info{
                            color:#488235;
                        }
                    }
                    
                }
                
                &__set{
                    width: 18%;
                    font-weight: 500;
                    &--activo{
                        color: indianred;
                    }
                }
            }
            
        }
        &__stats{
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            
        }
    }
   
    
}

@media (min-width: 1200px) {
    .DetallePartidoTorneoContainer{
        .DetallePartidoTorneo{
            &__informacion{
                &__resultados{
                    &__puntos{
                        &__punto{
                            &__pelota{
                                .iconoSaque{
                                    height: 36px;
                                    }
                                    .iconoSaque_hidden{
                                    visibility: hidden;
                                    height: 36px;
                                    } 
                            }
                        }
                    }
                }
            }
        }
    }
  
  }

  @media (min-width: 1400px) {
    .DetallePartidoTorneoContainer{
            width: 50%;
            &__upperInfo{
                &__stats{
                    display: none;
                }
            }
            
    }
    .DetallePartidoTorneoContainer:nth-child(odd){
        border-right: 1px solid rgba(128, 128, 128, 0.29);
    }
  
  }


  .modal-content{
    .nombres_stats{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-weight: bold;
        &_j1{
            color:#d05964,
        }
        &_j2{
            color:#38cd5a;
        }
    }
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .modal-title{
        width: 100%;
    }
    .title_close{
        cursor: pointer;
        display: inline;
    }
    .title_stats{
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .container_stats{
        width: 100%;
        height: 20px;
        border: 1px solid #000;
        display: flex;
      }
      
      .first-progrss{
        width: 50%;
        background:#d05964;
      }
      .second-progrss{
        width: 50%;
        background:#38cd5a;
      }
}