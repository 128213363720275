@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
body {
    font-family: 'Open Sans', sans-serif;
}  
.Torneo{
    &--doble{
        margin-bottom: 20px
    }
    height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    &__containerPartido{
        min-height: inherit;
        &--padding0{
            padding:0px;
            margin: 0px;
        }
        &__divTituloLogo{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            box-shadow: 5px 5px 10px #888888;
            img{
              width: 120px;  
            }
        }
        &__logos{
            padding: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            &--item{
                padding: 8px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
            }
        }
        &__nombreSolitario{
            padding:8px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-weight: 700;
            border-bottom: 1px solid #9098a7;
           
        }
    }
}

