

.TorneosWrapper{
  min-height: inherit;
  &__torneos{
    min-height: inherit;
  }
    &__divTituloLogo{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        box-shadow: 5px 5px 10px #888888;
            img{
              width: 120px;  
              &.mapfre{
                width: 8%;  
              }
              &.rmct{
                width: 8%;  
              }
              &.rfet{
                width: 8%;  
              }
              &.rfet2{
                width: 12%;  
              }
            }
    }
}

@media (min-width: 1500px) {
    .TorneosWrapper{
            &__torneos{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                
                
                &__torneo{
                    width: 100%;
                    
                    padding: 20px;
                }
                .doble{
                    width: 50%;
                }
            }
    }
  }