.PartidosContainer{
    background-color: white;
    margin: 0px;
    padding: 0px;
    max-width: 2500px;
    display: flex;
    flex-direction: column;


    text-align: center;
}
.flex{
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-top: 0px;
    cursor:pointer;
 
}
.chueron{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    font-size: 20px;
}
.infoTorneo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.genero{
    font-family: Light;
    font-style: italic;
    opacity: 0.5;
    text-align: left;
    padding-left: 10px;
}
@media (max-width:768px){
    .PartidosContainer h2,.PartidosContainer .genero{
        text-align:left;
 
    }
    .PartidosContainer h2{
        font-size: 20px;
    }
    .Partido{
        width: 100%;
        margin-bottom: 10px;
        padding-top: 0px;
        padding-bottom:0px;
    }
    .torneo_grande{
        border: none;
    }

}