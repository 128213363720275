@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
body {
    font-family: 'Open Sans', sans-serif;
}  
.mainContainer{
    width: 100%;
   
    display: flex;
    align-content: center;
    flex-direction: column;
}
.mainContainer2{width:100%}
.padding0{
    padding:0px;
    margin: 0px;
}
.border1{
    border-left: 2px solid;
}
.border1{
    border-left: 2px solid;
    border-right: 2px solid;
}
.border2{
    border-right: 2px solid;
}
@media (max-width:768px){
    .mainContainer{
        max-width: 100%;
    }

    .border2{
        border:none;
    }
}
@media (max-width:500px){
    .maincontainer2{
        width: 100%;
    }


}
