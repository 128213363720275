
.contianerPartidoTv{
    height: 100vh;
    background-size: cover;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tenis{
    &.contianerPartidoTv{
        background-image: url('../../Imagenes/pista3.jpg');
    }
    .resultadosTv{
        background-color:var(--greenTenis);
    }
    .grupo2{
        color: var(--greenTenis);
    }
    .footer{
        background-color: var(--greenTenisLogosBackGround);
    }
}

.pingPong{
    &.contianerPartidoTv{
        background-image: url('../../Imagenes/pp2.jpg');
    }
    .resultadosTv{
        background-color:var(--bluepp);
    }
    .grupo2{
        color: var(--bluepp);
    }
    .footer{
        background-color: var(--bluepp);
    }
    .cabecera .nombretorneo{
        color: var(--bluepp);
    }


}

.resultadosTv{
    height: 50vh;
    border-radius: 5px;
}
.texto_nombres{
    font-family: arial;
    font-weight: bold;
    font-size: 7vw;
    color: aliceblue;
}
.nombresTv{
    padding: 0rem 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.pistaTv{
    width: 100%;
    display: flex;
    color: #114d0b;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.separador{
    color: white;
    min-height: 4px;
    height: 4px;
    background-color: white;
    border: none;
    margin: 0;
}

.jugador1Tv, .jugador2Tv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pelotaTv{
    width: 5vw;
}

.logoPista{
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1vw;
}


.jugadorTv{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.resultadoTv{
    width: 45%;
    min-width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10vw;
    line-height: 29vh;
    font-family: arial;
    font-weight: bold;
    justify-content: space-evenly;
}
.nombre_jugador{
    text-align: -webkit-left;
    line-height: 11vh;
}
.textoPequeño{
    font-size: 5vw;
    line-height: 12vh;
}
.grupo1{    
    width: 66%;
    align-items: center;
    flex-direction: row;
    display: flex;
    color: aliceblue;
    justify-content: center;
}
.grupo2{    
    line-height: 10vh;
    width: 33%;
    align-items: center;
    flex-direction: row;
    display: flex;
    background-color: white;
    justify-content: center;
}
.setTv{
    padding: 0 1vw;
}



.logoTv{
   height: 10vh;
}
.logoTv2{
    width: 10vw;
}
.logoTv3{
    width:5vw;
}
.logoTv4{
    width: 13vw;
}
.logoTv5{
    width: 18vw;
}
.dominos{
    width: 100px;
    height: 100px;
}
.altura_modificada{
    height: 50px;
}
.logo_club{
    width: 15vh;
}

.partidotv{
    background-image: url('../../Imagenes/pista3.jpg');
}

.logo_club{
    width: 15vh;
}

.logo_pista{
    width: 15vh;
}
@media (min-width: 768px) {
    .logo_club{
        width: 22vh;
    }
    .logoPista .logo_pista{
        width: 22vh;
    }

    .resultadoTv{
        width: 33%;
    }
    .texto_nombres{
        font-size: 7vw;
    }
    .grupo2{    
        line-height: 25vh;
    }
    .logosBlanco{
        height: 13vh;
    }
    .resultadosTv{
        height: 60vh;
    }
  }

  @media  (orientation: landscape) and (max-width: 900px) {
                    .resultadosTv {
                        height: 53vh;
                    }
                    .resultadoTv{
                        font-size: 7vw;
                    }
                    .texto_nombres{
                        font-size: 7vw;
                    }
                    .grupo2 {
                        line-height: 16vh;
                    }
                    .logo_pista {
                        width: 22vh;
                    }
                    .logo_club {
                        width: 25vh;
                    }
                    .logoTv {
                        height: 15vh;
                    }
}
