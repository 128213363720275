
.PartidosContainer{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    &__titulo{
        display: flex;
        color: black;
        flex-direction: column;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center;
        font-style: italic;
        
    }
    &__divTituloLogo{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        box-shadow: 5px 5px 10px #888888;
        &__titulo{
            width: 75%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            padding: 16px;
        }
        &__logo{
            width: 25%;
            img{
              width: 120px;  
            }
            &--aux{
                display: flex;
                flex-direction: row;
                align-content: center;
                align-items: center;
            }
        }
    }
    &__divTitulo{
        
        &--logo{
            width: 120px; 
        }
    }
    &__Partidos{
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-start;
        &--doble{
            display: flex;
            flex-direction: column;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: flex-start;
        }
    }
    &__noInfo{
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        & img{
            max-width: 100vw;
        }
    }
}
.PartidosPadelContainer{

    margin: 0px;
    padding: 0px;
    max-width: 2500px;
    display: flex;
    flex-direction: column;
    

    text-align: center;
}
.PartidosPadelContainer .nombrePadel {
    color:black;
}

.flexSpain{
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 0px;
    cursor:pointer;
 
}


.chueron{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    font-size: 20px;
}
.infoTorneoPadel{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
.genero{
    font-family: Light;
    font-style: italic;
    opacity: 0.5;
    text-align: left;
    padding-left: 10px;
}

.logo_padel2{
    width: 230px;
}
.logo_padel3{
    width: 200px;
}

@media(max-width: 768px) {
    .PartidosContainer{
        &__divTituloLogo{
            &__logo{
                &--aux{
                    &__img{
                        width: 80px !important;
                    }
                }
            }
        }
    }
 
}
@media (min-width: 1400px) {
    .PartidosContainer{
        &__divTituloLogo{
            &__titulo{
                font-size: 48px;
            }
        }
        &__Partidos{
            display: flex;
            flex-direction: row;
            align-content: flex-start;
            justify-content: space-between;
            /* max-width: 100vw; */
            flex-wrap: wrap;
            
            
        
            &--doble{
                display: flex;
                flex-direction: row;
                align-content: flex-start;
                justify-content: space-between;
                /* max-width: 100vw; */
                flex-wrap: wrap;
            }
        }
    }
    .DetallePartidoTorneo__horaPista__hora, .DetallePartidoTorneo__horaPista__hora__horarioComienzo{
        font-size: 1.4rem;
    }
  }