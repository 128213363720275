.pieTorneos{
    background-color: #f8f9fa;
    padding: 10px;
    width: 100%;
    &__logos{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        &--item{
            &__logo{
                height: 9vh;
                padding: 10px;
            }
        }
    }
    &__legal{
        font-size: 1.5vw;
    }
}